<template>
  <section class="sign-in d-flex flex-column">
    <div class="btn-back d-flex" @click="back()">
      <img src="@/assets/icons/Arrow-left.svg" alt="">
      <h6 class="title">Back</h6>
    </div>
    <div class="content">
      <div class="d-flex flex-row align-end justify-between">
        <h3>Become a member</h3>
        <div style="color: #AAAAAA;font-size: 14px">Step 1/2</div>
      </div>
      <h4 class="title">General info</h4>
      <form class="d-flex flex-column">
        <div class="checkbox-container">
          <div class="d-flex ">
            <div class="custom-checkbox-container">
              <input class="custom-checkbox" v-model="userInfo.typeAccount"  type="radio" :value="0">
              <label class="custom-label">Personal account</label>
            </div>
            <div class="custom-checkbox-container d-flex">
              <input class="custom-checkbox" v-model="userInfo.typeAccount" type="radio" :value="1">
              <label class="custom-label">Bussiness account</label>
            </div>
          </div>
        </div>
        <div v-if="userInfo.typeAccount === 1">
          <input class="company" type="text" placeholder="Company name *">
        </div>
        <div class="container">
          <label class="input-containers">
            <input type="text" placeholder="First Name *">
          </label>
          <label class="input-containers">
            <input type="text" placeholder="Last Name *">
          </label>
        </div>
        <label class="d-flex flex-row align-center justify-between">
          <VuePhoneNumberInput default-country-code="NL"
                               dark-color="#262627"
                               :dark="true"
                               :no-example="true"
                               :color="'#262627'"
                               :only-countries="['NL']"
                               size="lg" />
          <input type="text" placeholder="Email*">
        </label>
        <div class="container d-flex">
          <div class="input-containers">
            <label class="password-container d-flex flex-row align-center">
              <input type="password" autocomplete="no" placeholder="Password*">
              <img src="@/assets/icons/Eye.svg" alt="">
            </label>
          </div>
          <div class="input-containers">
            <label class="password-container d-flex flex-row align-center">
              <input type="password" autocomplete="no" placeholder="Confirm password*">
              <img src="@/assets/icons/Eye.svg" alt="">
            </label>
          </div>
        </div>
        <div class="next-step d-flex">
          <div class="btn-next-step" @click="transitionToNextStep">
            <h6>Continue</h6>
          </div>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

export default {
  name: "SignUp",
  data(){
    return{
      userInfo:{
        fname:null,
        lname:null,
        email:null,
        phone:null,
        password:null,
        companyName:null,
        typeAccount:0
      }
    }
  },
  methods:{
    back(){
      this.$router.push('/')
    },
    transitionToNextStep(){
      this.$router.push('/signUpSecondStep')
    }
  },
  components:{
    VuePhoneNumberInput
  }
}
</script>

<style scoped lang="scss">
@import "SignUp.scss";
#MazPhoneNumberInput{
  width: 280px;
}
</style>